.gallery {
    padding: 0 5vw 100px;
    position: relative;
    background-image: linear-gradient(0deg, white 0%, transparent 20%, transparent 80%, white 100%);
    width: calc(95% - 2 * 5vw);
    margin: auto;
}

.gallery::after {
    content: "";
    background-image: url('/img/topography.jpg');
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
}

.gallery .title {
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
    font-family: 'Bebas Kai', sans-serif;
    font-weight: normal;
    font-size: 6rem;
    letter-spacing: 0.3rem;
}

.gallery .title.blue {
    color: #81bfc8;
}

.gallery .title.green {
    color: #d2d5a9;
}

.gallery .title.red {
    color: #e8c2ca;
}

.gallery img {
    width: 23vw;
    height: calc(23vw * 669 / 1000);
    object-fit: cover;
    margin: auto;
    cursor: pointer;
    box-shadow: 0 14px 38px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22);
}

@media screen and (max-width: 1300px) {
    .gallery img {
        width: 35vw;
        height: calc(35vw * 669 / 1000);
    }
}

@media screen and (max-width: 960px) {
    .gallery img {
        width: 60vw;
        height: calc(60vw * 669 / 1000);
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 800px) {
    .gallery .title {
        font-size: 5.5rem;
    }
}

@media screen and (max-width: 600px) {
    .gallery .title {
        font-size: 4rem;
    }
}

.gallery .slick-slide:nth-of-type(2n) img {
    margin-bottom: 50px;
}

.gallery .slick-slide:nth-of-type(2n+1) img {
    margin-top: 50px;
}

.gallery .slick-slider .slick-prev::before,
.gallery .slick-slider .slick-next::before {
    color: #222222;
    font-family: 'LineIcons';
    font-weight: 600;
    opacity: 1;
}

.gallery .slick-slider .slick-next,
.gallery .slick-slider .slick-prev {
    transition: all ease-in-out 0.15s;
}

.gallery .slick-slider .slick-next:hover {
    padding-left: 5px;
}

.gallery .slick-slider .slick-prev:hover {
    left: -30px;
    padding-right: 5px;
}

.gallery .slick-slider .slick-prev::before {
    content: '\ea5a';
}

.gallery .slick-slider .slick-next::before {
    content: '\ea5c';
}

.gallery .slick-slider .slick-list {
    padding: 30px 0 55px;
}

.gallery > div:nth-of-type(2) > div {
    left: 0;
}

.gallery .slideshow-container {
    position: fixed !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: #1b1b1bf2;
}

.gallery .slideshow-container .slide {
    background-size: contain;
}

.gallery .slideshow-container .show-index.is-dot {
    width: 100%;
    justify-content: center;
    left: unset;
}

.gallery .slideshow-container .arrows .btn-arrow {
    top: calc(50% - 25px);
}

.gallery .close-button {
    width: 70px;
    height: 70px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    font-size: 5px;
    transform: rotateZ(45deg);
    cursor: pointer;
}

.gallery .close-button::before,
.gallery .close-button::after {
    content: '';
    display: block;
    background: rgba(256, 256, 256, 0.6);
    position: absolute;
}

.gallery .close-button::before {
    top: calc((70px - 8em) / 2);
    left: calc((70px - 2em) / 2);
    width: 2em;
    height: 8em;
}

.gallery .close-button::after {
    top: calc((70px - 2em) / 2);
    left: calc((70px - 8em) / 2);
    width: 8em;
    height: 2em;
}