.notification {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 5px 20px 0px rgb(0 0 0 / 8%);
    border-radius: 5px;
    margin-bottom: 15px;
    width: 350px;
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
}

.notification::before {
    content: "";
    height: 100%;
    width: 5px;
    position: absolute;
}

.notification.exit {
    animation: SlideRight 0.4s;
    animation-fill-mode: forwards;
}

.notification.success::before {
    background-color: #47D764;
}

.notification.error::before {
    background-color: #ff355c;
}

.notification > div {
    padding: 10px 10px;
}

.notification > div > p {
    margin: 0;
    color: #94959a;
    font-size: 0.95rem;
}

.notification > div > p.title {
    color: #282c36;
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.notification > i {
    margin: 0 15px 0 25px;
    font-size: 1.1rem;
    font-weight: bold;
}

.notification.success > i {
    color: #47d764;
}

.notification.error > i {
    color: #ff355c;
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}