.welcome-section {
    overflow: hidden;
}

.welcome-section .video {
    height: 100vh;
    width: 100%;
}

.welcome-section .video video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
}

.welcome-section .overlay {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(#38383854 0%, transparent 15%);
    background-color: #0794a230;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    grid-template-rows: 2fr 4fr 3fr;
    justify-items: center;
    align-items: center;
}

.welcome-section .overlay>div:first-child {
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome-section .overlay>div:first-child h1,
.welcome-section .overlay>div:first-child h2 {
    margin: 0;
    font-weight: normal;
    text-shadow: #6161614a 0px 0px 20px;
    text-align: center;
    padding: 0 20px;
}

.welcome-section .overlay>div:first-child h1 {
    font-family: 'Cartel Deux Sharp', sans-serif;
    font-size: 8rem;
    color: white;
}

.welcome-section .overlay>div:first-child h2 {
    font-family: 'Bebas Kai', sans-serif;
    font-size: 5rem;
    letter-spacing: 1.5rem;
    color: #f1f1f1d1;
}

.welcome-section .overlay>div:first-child p {
    color: white;
    font-size: 1.4rem;
    font-family: 'RobotoLight', sans-serif;
    letter-spacing: 0.07rem;
    text-shadow: #6161614a 0px 0px 20px;
    text-align: center;
    padding: 0 20px;
}

.welcome-section .overlay>div:last-child {
    grid-row: 3;
    width: 100%;
    align-self: start;
}

@media screen and (max-width: 1000px) {
    .welcome-section .overlay {
        grid-template-rows: 1fr 3fr 4fr;
    }

    .welcome-section .overlay>div:first-child h1 {
        font-size: 13vw;
    }
    
    .welcome-section .overlay>div:first-child h2 {
        font-size: 9vw;
        letter-spacing: 2vw;
    }
}

@media screen and (max-height: 600px) {
    .welcome-section .overlay,
    .welcome-section .video {
        height: 120vh;
    }
}