.booking .title {
    margin-top: 50px;
}

.booking .title h2:nth-child(1) {
    font-size: 6rem;
}

.booking .title h2:nth-child(2) {
    font-size: 4rem;
    letter-spacing: 0.3rem;
}

#booking-iframe {
    margin: 50px 20%;
    width: 60%;
    height: 800px;
    border: none;
}

@media screen and (max-width: 1400px) {
    #booking-iframe {
        margin: 50px 10%;
        width: 80%;
        height: 800px;
        border: none;
    }
}

@media screen and (max-width: 1000px) {
    #booking-iframe {
        margin: 50px 0;
        width: 100%;
        height: 800px;
        border: none;
    }
}

@media screen and (max-width: 500px) {
    .booking .title h2:nth-child(1) {
        font-size: 5rem;
    }
    
    .booking .title h2:nth-child(2) {
        font-size: 3rem;
        letter-spacing: 0.3rem;
    }
}