.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  padding-top: 80px;
}

p,
li {
  font-family: 'RobotoLight', sans-serif;;
}

.bold {
  font-weight: 600 !important;
}


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 550ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 550ms ease-in;
}

div.transition-group {
    position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

h1, h2, h3, h4, h5, h6, p, li, a {
  color: #222222;
}

.bold {
  font-weight: 600;
}