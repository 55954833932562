#camping-item .main-content {
    padding-top: 50px;
}

#camping-item .entry {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 8vw;
    align-items: center;
    padding: 70px 9vw 70px;
}

#camping-item .main-content .image {
    display: flex;
    justify-content: center;
    height: 90vh;
    position: relative;
}

#camping-item .main-content .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

#camping-item .main-content > .title {
    text-transform: uppercase;
    text-align: center;
    font-family: 'Bebas Kai', sans-serif;
    font-size: 7rem;
    letter-spacing: 0.5rem;
    margin: 0;
    font-weight: normal;
    grid-row: 1;
    grid-column: 1 / 3;
}

#camping-item .main-content.blue .title {
    color: #81bfc8;
}

#camping-item .main-content.red .title {
    color: #e8c2ca;
}

#camping-item .main-content.green .title {
    color: #d2d5a9;
}

#camping-item .main-content .description,
#camping-item .main-content .description > div {
    display: grid;
}

#camping-item .main-content .description .inventory-link {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'RobotoRegular', sans-serif;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 1em;
}

#camping-item .main-content .description .inventory-link i {
    font-size: 1.3rem;
    margin-right: 5px;
    transition: all ease-in-out 0.2s;
}

#camping-item .main-content .description .inventory-link:hover i {
    transform: scale(1.2);
}

#camping-item .main-content .description .subtitle {
    display: inline-block;
    text-align: center;
    position: relative;
    justify-self: center;
    font-family: Bebas Kai;
    font-weight: normal;
    font-size: 2.2rem;
}

#camping-item .main-content .description .subtitle:not(:first-of-type) {
    margin-top: 60px;
}

#camping-item .main-content .description .subtitle::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -30%;
    left: -12%;
    right: 55%;
    bottom: 45%;
}

#camping-item .main-content.blue .description .subtitle::after {
    background-color: #81bfc8;
}

#camping-item .main-content.red .description .subtitle::after {
    background-color: #e8c2ca;
}

#camping-item .main-content.green .description .subtitle::after {
    background-color: #d2d5a9;
}

@media screen and (max-width: 1000px) {
    #camping-item .entry {
        display: flex;
        flex-direction: column;
    }

    #camping-item .entry .description {
        order: 2;
    }

    #camping-item .entry .image {
        margin-bottom: 50px;
        padding-top: 70%;
        width: 100%;
        height: 100%;
        order: 1;
    }

    #camping-item .entry .image img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

@media screen and (max-width: 800px) {
    #camping-item .main-content > .title {
        font-size: 5.5rem;
    }

    #camping-item .main-content .description .subtitle {
        font-size: 1.9rem;
        margin: 1em 10%;
    }
}

@media screen and (max-width: 600px) {
    #camping-item .main-content > .title {
        font-size: 4rem;
    }
}