#restaurant-menu {
    margin-top: 10px;
    padding-left: 30vw;
    padding-right: 30vw;
}

@media screen and (max-width: 1000px) {
    #restaurant-menu {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

#restaurant-menu h2 {
    display: flex;
    justify-content: center;
    font-size: 5rem;
    font-weight: normal;
    text-align: center;
}

#restaurant-menu h2:not(:nth-of-type(1)) {
    margin-top: 150px;
}

#restaurant-menu h2:nth-of-type(1) {
    font-family: "Bernier", sans-serif;
}

#restaurant-menu h2:nth-of-type(2) {
    font-family: "Valeria", sans-serif;
}

#restaurant-menu h2:nth-of-type(3) {
    font-family: "TriumphWheels", sans-serif;
}

#restaurant-menu h2:nth-of-type(4) {
    font-family: "Billionaire", sans-serif;
}

#restaurant-menu > div {
    display: grid;
    grid-template-columns: 7fr 2fr;
    margin-top: 50px;
}

#restaurant-menu > div.symetric-grid {
    grid-template-columns: 1fr 1fr;
    margin: 0;
}

#restaurant-menu > div.wine-grid {
    grid-template-columns: 3fr repeat(3, 1fr);
    margin: 0;
}

#restaurant-menu div h3 {
    grid-column: 1 / 3;
    text-align: center;
    font-family: "LemonMilk", sans-serif;
    font-weight: normal;
    font-size: 1.7rem;
    border-top-style: double;
    width: 100%;
    margin: 0;
    padding: 5px 0;
}

#restaurant-menu div .subtitle {
    grid-column: 1 / 3;
    border: none;
    text-align: center;
}

#restaurant-menu div .top {
    border-top: 1px solid black;
}

#restaurant-menu div h4,
#restaurant-menu div .price {
    font-family: "LemonMilk", sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
    margin: 0;
}

#restaurant-menu div h4,
#restaurant-menu div p:not(.price):not(.subtitle) {
    padding-left: 15px;
    border-right: 1px solid black;
}

#restaurant-menu div .price {
    text-align: end;
    width: calc(100% - 15px);
    padding-right: 15px;
}

#restaurant-menu div p {
    font-family: "Typewriter", sans-serif;
    margin: 0;
}

#restaurant-menu div h4 > span {
    font-size: 1rem;
}

#restaurant-menu div h3 > span {
    font-size: 1.3rem;
}

#restaurant-menu div .padding-top {
    padding-top: 10px;
}

#restaurant-menu div .padding-bottom {
    padding-bottom: 10px;
}

#restaurant-menu div .no-border {
    border-right: none !important;
}

#restaurant-menu div .border-left {
    border-left: 1px solid black;
}

#restaurant-menu div .border-right {
    border-right: 1px solid black;
}

#restaurant-menu div .text-center {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
}