.booking-ribbon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 9vw;
}

.booking-ribbon .button {
    margin-top: 50px;
}

.booking-ribbon .fancy-title h2:nth-of-type(1) {
    font-size: 6rem;
}

.booking-ribbon .fancy-title h2:nth-of-type(2) {
    font-size: 3rem;
    letter-spacing: 0.3rem;
}

@media screen and (max-width: 500px) {
    .booking-ribbon .fancy-title h2:nth-of-type(1) {
        font-size: 5.5rem;
    }
    
    .booking-ribbon .fancy-title h2:nth-of-type(2) {
        font-size: 2.5rem;
        letter-spacing: 0.2rem;
    }
}

@media screen and (max-width: 400px) {
    .booking-ribbon .fancy-title h2:nth-of-type(1) {
        font-size: 4.5rem;
    }
    
    .booking-ribbon .fancy-title h2:nth-of-type(2) {
        font-size: 2rem;
    }
}