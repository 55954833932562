#admin-panel {
    width: 100%;
    height: 100vh;
    display: flex;
}

#admin-panel .sidebar {
    width: 300px;
    height: calc(100% - 2*50px);
    background-color: #0197ab;
    border-radius: 0 15px 15px 0;
    box-shadow: 3px 0px 17px 0px #026f7d70;
    padding: 50px;
    display: grid;
    grid-template: 150px 1fr 70px / 1fr;
}

#admin-panel .sidebar a {
    width: 85%;
    justify-self: center;
}

#admin-panel .sidebar a img {
    width: 100%;
}

#admin-panel .sidebar .logout-button {
    border: none;
    border-radius: 30px;
    background-color: white;
    padding: 10px 20px;
    color: #0197ab;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    outline: none;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
    justify-self: center;
    align-self: end;
    margin-top: 1rem;
}

#admin-panel .sidebar .logout-button:hover {
    transform: scale(1.07);
}

#admin-panel .main {
    width: calc(100% - 300px);
    height: calc(100% - 2*30px);
    padding: 30px 100px;
    overflow-y: auto;
    position: relative;
}

#admin-panel .nav-buttons button {
    background: none;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1.3rem;
    font-family: "Bebas Kai", sans-serif;
    letter-spacing: 0.05rem;
    cursor: pointer;
    width: 100%;
    text-align: start;
    padding: 10px 20px;
    margin-bottom: 5px;
    outline: none;
    transition: all ease-in-out 150ms;
}

#admin-panel .nav-buttons button:hover {
    background-color: #028ea0;
}

#admin-panel .nav-buttons button.selected {
    background-color: white;
    color: #0197ab;
}