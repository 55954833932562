.menu-entry p {
    margin: 0;
}

.menu-entry p:nth-of-type(1) {
    font-family: 'Cartel Deux Sharp', sans-serif;
    color: #81bfc8;
    font-size: 4rem;
}

.menu-entry p:nth-of-type(2) {
    font-family: 'Bebas Kai', sans-serif;
    color: #d3d3d3;
    font-size: 2.5rem;
}

.menu-entry.left p:nth-of-type(2) {
    padding-left: 100px;
}

.menu-entry.right p:nth-of-type(2) {
    padding-right: 100px;
}

@media screen and (max-width: 1300px) {
    .menu-entry p:nth-of-type(1) {
        font-size: 3.2rem;
    }
    
    .menu-entry p:nth-of-type(2) {
        font-size: 2.2rem;
    }

    .menu-entry.left p:nth-of-type(2) {
        padding-left: 80px;
    }
    
    .menu-entry.right p:nth-of-type(2) {
        padding-right: 80px;
    }
}

@media screen and (max-width: 1000px) {
    .menu-entry.left p:nth-of-type(2) {
        padding-left: 0;
    }
    
    .menu-entry.right p:nth-of-type(2) {
        padding-right: 0;
    }

    .menu-entry p {
        text-align: center;
    }
}