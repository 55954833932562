@font-face {
  font-family: "Cartel Deux Sharp";
  src: url("/fonts/CartelDeuxSharp.otf");
}

@font-face {
  font-family: "Bebas Kai";
  src: url("/fonts/BebasKai.otf");
}

@font-face {
  font-family: "Raleway Regular";
  src: url("/fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoLight";
  src: url("/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "RobotoThin";
  src: url("/fonts/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Bernier";
  src: url("/fonts/BERNIERDistressed-Regular.ttf");
}

@font-face {
  font-family: "Billionaire";
  src: url("/fonts/BillionaireMediumGrunge.ttf");
}

@font-face {
  font-family: "TriumphWheels";
  src: url("/fonts/Triumph wheels rough.ttf");
}

@font-face {
  font-family: "Valeria";
  src: url("/fonts/ValeriaBoldGrunge.ttf");
}

@font-face {
  font-family: "LemonMilk";
  src: url("/fonts/LEMONMILK-Medium.otf");
}

@font-face {
  font-family: "Typewriter";
  src: url("/fonts/JMH Typewriter dry.otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
