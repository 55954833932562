.navbar .dropdown {
    width: 100%;
    height: 100%;
    position: relative;
}

.navbar .dropdown:hover .dropdown-button {
    transform: scale(1.1);
    letter-spacing: 0.2rem;
}

.navbar .dropdown .dropdown-button {
    font-size: 1.2rem;
    border: none;
    color: black;
    font-family: 'Bebas Kai', sans-serif;
    letter-spacing: 0.1rem;
    background-color: transparent;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}

.navbar .dropdown-content {
    display: none;
    opacity: 0;
    position: absolute;
    top: 80px;
    background-color: white;
    min-width: calc(100% - 2 * 20px);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
    z-index: 1;
    transition: opacity ease-in-out 0.2s;
    padding: 10px 20px;
}

.navbar .dropdown-content a {
    float: none;
    color: black !important;
    padding: 12px 0;
    text-decoration: none;
    font-size: 1.1rem;
    display: block;
    text-align: left;
    letter-spacing: 0.1rem;
    transition: all ease-in-out 0.2s;
}

.navbar .dropdown:hover .dropdown-content {
    display: block;
    opacity: 1;
}

.navbar .dropdown .dropdown-content a:hover {
    letter-spacing: 0.15rem;
}

.navbar.homepage.top .dropdown-button {
    color: white;
}

@media screen and (max-width: 1000px) {
    .navbar .dropdown {
        grid-column: 1 / 3;
        display: none;
    }

    .navbar.open .dropdown {
        display: block;
        height: auto;
    }

    .dropdown:not(.open):hover .dropdown-content {
        display: none !important;
    }

    .dropdown.open .dropdown-content {
        display: block;
        opacity: 1;
    }

    .navbar.open .dropdown-content {
        position: relative;
        top: 0;
        box-shadow: none;
        background-color: #f7f7f7;
    }

    .navbar.open .dropdown-button {
        color: black !important;
        padding: 10px 0;
        font-size: 1.5rem;
    }
}