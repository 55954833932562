.fancy-title h2:first-of-type,
.fancy-title h2:last-of-type {
    text-align: center;
    margin: 0;
    font-weight: normal;
    position: relative;
}

.fancy-title h2:nth-of-type(1) {
    font-family: 'Cartel Deux Sharp', sans-serif;
    z-index: 99;
}

.fancy-title h2:nth-of-type(2) {
    font-family: 'Bebas Kai', sans-serif;
    color: #d3d3d3;
}

.fancy-title.blue h2:nth-of-type(1) {
    color: #81bfc8;
}

.fancy-title.green h2:nth-of-type(1) {
    color: #d2d5a9;
}

.fancy-title.red h2:nth-of-type(1) {
    color: #e8c2ca;
}