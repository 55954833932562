.footer {
    padding: 100px 15%;
    background-image: url('/img/footer-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.footer .main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
}

.footer .main .logo {
    display: flex;
    align-items: flex-start;
    width: 20%;
}

.footer .main .logo img {
    object-fit: contain;
    width: 100%;
}

.footer .main .links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 4.2vw;
    grid-row-gap: 20px;
}

.footer .main .links a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

.footer .main .infos {
    display: flex;
    flex-direction: column;
}

.footer .main .infos .info {
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    margin: 0 0 30px;
}

.footer .main .infos .info:last-child {
    margin: 0;
}

.footer .main .infos .info i {
    font-weight: 600;
    margin-right: 10px;
}

.footer .languages {
    display: flex;
    justify-content: space-evenly;
}

.footer .languages > span {
    cursor: pointer;
}

.footer .languages > span > span {
    margin-right: 5px;
    transition: all ease-in-out 0.2s;
}

.footer .languages > span:hover > span {
    transform: scale(1.2);
}

@media screen and (max-width: 1500px) {
    .footer .main .links {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        padding: 100px 8%;
    }
}

@media screen and (max-width: 1000px) {
    .footer .main {
        flex-direction: column;
        align-items: center;
    }

    .footer .main .logo {
        width: 30%;
    }

    .footer .main .logo,
    .footer .main .links {
        margin-bottom: 50px;
    }

    .footer .main .links {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    .footer .main .infos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 4.2vw;
    }
}

@media screen and (max-width: 750px) {
    .footer .main .logo {
        width: 35%;
    }

    .footer .main .infos {
        display: flex;
        flex-direction: column;
    }

    .footer .main .links {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
    }

    .footer .languages {
        flex-direction: column;
        align-items: center;
    }

    .footer .languages > span:not(:last-of-type) {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 500px) {
    .footer {
        padding: 100px 5%;
    }

    .footer .main .logo {
        width: 50%;
    }

    .footer .main .infos {
        display: flex;
        flex-direction: column;
    }

    .footer .main .links {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
    }
}

@media screen and (max-width: 400px) {
    .footer .main .links {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(9, 1fr);
        justify-items: center;
    }

    .footer .main .infos {
        align-items: center;
    }
}