#restaurant .intro {
    display: grid;
    align-items: center;
    padding: 0 10vw;
    grid-template-columns: 25vw 49vw;
    grid-column-gap: 6vw;
    height: calc(100vh - 80px);
    position: relative;
    background-image: linear-gradient(90deg, white 10%, transparent 70%);
    overflow: hidden;
}

#restaurant .concert {
    display: grid;
    align-items: center;
    padding: 200px 10vw 150px;
    grid-template-columns: 49vw 25vw;
    grid-column-gap: 6vw;
    position: relative;
    overflow: hidden;
}

#restaurant .intro::after {
    content: "";
    background-image: url('/img/topography.jpg');
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
}

#restaurant .intro .image img,
#restaurant .concert img {
    width: 100%;
    height: calc(47vw * 600 / 960);
    object-fit: cover;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    z-index: 1;
}

#retaurant .intro > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
}

#restaurant .intro .fancy-title {
    margin-bottom: 70px;
}

#restaurant .intro .fancy-title h2:nth-of-type(1) {
    font-size: 8rem;
}

#restaurant .intro .fancy-title h2:nth-of-type(2) {
    font-size: 4rem;
    letter-spacing: 0.2rem;
}

#restaurant .concert .fancy-title h2:nth-of-type(1) {
    font-size: 7rem;
}

#restaurant .concert .fancy-title h2:nth-of-type(2) {
    font-size: 4rem;
    letter-spacing: 0.2rem;
}

#restaurant .concert p {
    text-align: center;
}

#restaurant .intro p,
#restaurant .concert p {
    line-height: 2.3rem;
    font-size: 1.3rem;
    letter-spacing: 0.04rem;
}

#restaurant .intro .icon {
    position: absolute;
    z-index: 0;
    top: -80px;
    left: -150px;
}

#restaurant .intro .icon img {
    height: 700px;
    width: 700px;
    object-fit: contain;
    transform: rotateZ(25deg);
    opacity: 0.2;
}

#restaurant .menu {
    padding: 80px 8vw;
}

#restaurant .menu .title h2 {
    font-family: 'Bebas Kai', sans-serif;
    text-align: center;
    font-weight: normal;
    font-size: 7rem;
    margin: 0;
    color: #81bfc8;
    padding-bottom: 80px;
}

#restaurant .menu .content {
    display: grid;
    grid-template-columns: 6fr 1fr 6fr;
    grid-template-rows: 350px 100px 350px 100px 350px;
    grid-column-gap: 10vw;
}

#restaurant .menu .content .text {
    align-self: center;
}

#restaurant .menu .content .text:nth-child(1) {
    grid-row: 1;
    grid-column: 2 / 4;
    text-align: start;
}

#restaurant .menu .content .text:nth-child(2) {
    grid-row: 3;
    grid-column: 1 / 3;
    text-align: end;
}

#restaurant .menu .content .text:nth-child(3) {
    grid-row: 5;
    grid-column: 2 / 4;
    text-align: start;
}

#restaurant .menu .content .image:nth-of-type(4) {
    grid-column: 1;
    grid-row: 1;
}

#restaurant .menu .content .image:nth-of-type(5) {
    grid-column: 3;
    grid-row: 3;
}

#restaurant .menu .content .image:nth-of-type(6) {
    grid-column: 1;
    grid-row: 5;
}

#restaurant .menu .content .image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-shadow: 0 14px 38px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22);
}

#restaurant .menu .content .text .menu-entry:not(:last-of-type) {
    padding-bottom: 50px;
}

@media screen and (max-width: 1800px) {
    #restaurant .intro .fancy-title h2:nth-of-type(1) {
        font-size: 6rem;
    }
    
    #restaurant .intro .fancy-title h2:nth-of-type(2) {
        font-size: 3rem;
    }

    #restaurant .concert .fancy-title h2:nth-of-type(1) {
        font-size: 6rem;
    }
    
    #restaurant .concert .fancy-title h2:nth-of-type(2) {
        font-size: 3rem;
    }

    #restaurant .intro {
        grid-template-columns: 30vw 44vw;
    }

    #restaurant .concert {
        grid-template-columns: 44vw 30vw;
    }
}

@media screen and (max-width: 1200px) {
    #restaurant .intro,
    #restaurant .concert {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1150px) {
    #restaurant .menu .content {
        grid-column-gap: 5vw;
    }
}

@media screen and (max-width: 1000px) {
    #restaurant .intro,
    #restaurant .concert {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    
    #restaurant .intro {
        padding: 70px 10vw;
    }

    #restaurant .concert {
        padding: 70px 10vw;
    }

    #restaurant .intro .image,
    #restaurant .concert .image {
        padding-top: 60%;
        position: relative;
        width: 100%;
        height: 100%;
    }

    #restaurant .intro .image {
        margin-top: 50px;
    }

    #restaurant .concert .image {
        margin-bottom: 50px;
    }

    #restaurant .intro .image img,
    #restaurant .concert .image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #restaurant .menu .content {
        display: flex;
        flex-direction: column;
    }

    #restaurant .menu .content .text:nth-child(1) {
        order: 2;
    }
    
    #restaurant .menu .content .text:nth-child(2) {
        order: 4;
    }
    
    #restaurant .menu .content .text:nth-child(3) {
        order: 6;
    }
    
    #restaurant .menu .content .image:nth-of-type(4) {
        order: 1;
    }
    
    #restaurant .menu .content .image:nth-of-type(5) {
        order: 3;
    }
    
    #restaurant .menu .content .image:nth-of-type(6) {
        order: 5;
    }

    #restaurant .menu .content .image,
    #restaurant .menu .content .text:not(:last-child) {
        margin-bottom: 60px;
    }

    #restaurant .menu .content .image
    {
        margin-left: 60px;
        margin-right: 60px;
    }

    #restaurant .menu .title h2 {
        font-size: 6rem;
    }
}

@media screen and (max-width: 800px) {
    #restaurant .intro .icon {
        top: -60px;
        left: -100px;
    }

    #restaurant .intro .icon img {
        height: 500px;
        width: 500px;
    }

    #restaurant .intro p,
    #restaurant .concert p {
        font-size: 1.2rem;
    }

    #restaurant .menu .title h2 {
        font-size: 5rem;
    }
}

@media screen and (max-width: 500px) {
    #restaurant .intro .icon {
        top: -40px;
        left: -70px;
    }

    #restaurant .intro .icon img {
        height: 350px;
        width: 350px;
    }

    #restaurant .intro .fancy-title h2:nth-of-type(1),
    #restaurant .concert .fancy-title h2:nth-of-type(1) {
        font-size: 5rem;
    }
    
    #restaurant .intro .fancy-title h2:nth-of-type(2),
    #restaurant .concert .fancy-title h2:nth-of-type(2) {
        font-size: 3rem;
    }
}

@media screen and (max-width: 400px) {
    #restaurant .intro .fancy-title h2:nth-of-type(1),
    #restaurant .concert .fancy-title h2:nth-of-type(1) {
        font-size: 4.5rem;
    }
    
    #restaurant .intro .fancy-title h2:nth-of-type(2),
    #restaurant .concert .fancy-title h2:nth-of-type(2) {
        font-size: 2.5rem;
        letter-spacing: 0.1rem;
    }

    #restaurant .menu .content .image
    {
        margin-left: 40px;
        margin-right: 40px;
    }

    #restaurant .menu .title h2 {
        font-size: 4rem;
    }
}