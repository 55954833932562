.title-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    background-color: #fbfbfb;
    border-radius: 15px;
    padding: 30px 40px;
}

.title-item .title {
    grid-column: 1 / 3;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    margin-bottom: 20px;
    margin-top: 0;
}

.title-item > p {
    grid-column: 1 / 3;
    font-weight: bold;
    font-family: "Bebas Kai", sans-serif;
    font-size: 1.05rem;
    letter-spacing: 0.05rem;
}

.title-item > p span {
    margin-right: 7px;
}

.title-item .form {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}

.title-item .form div {
    display: flex;
    flex-direction: column;
}

.title-item .form label {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    margin-bottom: 10px;
    font-family: "Bebas Kai", sans-serif;
    margin-left: 30px;
}

.title-item .form input {
    border: 1px solid #e7e7e7;
    border-radius: 30px;
    background-color: white;
    padding: 15px 30px;
    font-size: 1rem;
    width: 100%;
    outline: none;
    transition: border ease-in-out 200ms;
}

.title-item .form input:focus {
    border: 1px solid #0197ab;
}

.title-item .form input:first-of-type {
    margin-bottom: 20px;
}

.title-item .preview {
    margin-bottom: 25px;
}

.title-item .preview h3 {
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    font-weight: normal;
}

.title-item .preview .fancy-title h2:first-of-type {
    font-size: 6rem;
}

.title-item .preview .fancy-title h2:last-of-type {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
}

.title-item > button {
    border: none;
    border-radius: 30px;
    background-color: #0197ab;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    outline: none;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
    grid-column: 2;
    justify-self: end;
}

.title-item > button:hover {
    transform: scale(1.07);
}

.title-item > button .spinner-icon {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}