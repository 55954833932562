.zoomable-image {
    cursor: pointer;
}

.zoomable-image > div:nth-of-type(1) {
    position: relative;
}

.zoomable-image > div:nth-of-type(1) > .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25252500;
    opacity: 0;
    transition: all 200ms ease-in-out;
}

.zoomable-image > div:nth-of-type(1) > .overlay > i {
    color: white;
    opacity: 0.9;
    font-size: 2.5rem;
}

.zoomable-image > div:nth-of-type(1) > .overlay:hover {
    background-color: #2525254C;
    opacity: 1;
}

.zoomable-image > div:nth-of-type(2) > div {
    left: 0;
}

.zoomable-image .slideshow-container {
    position: fixed !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: #1b1b1bf2;
}

.zoomable-image .slideshow-container .slide {
    background-size: contain;
}

.zoomable-image .slideshow-container .show-index.is-dot {
    width: 100%;
    justify-content: center;
    left: unset;
}

.zoomable-image .slideshow-container .arrows .btn-arrow {
    top: calc(50% - 25px);
}

.zoomable-image .close-button {
    width: 70px;
    height: 70px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    font-size: 5px;
    transform: rotateZ(45deg);
    cursor: pointer;
}

.zoomable-image .close-button::before,
.zoomable-image .close-button::after {
    content: '';
    display: block;
    background: rgba(256, 256, 256, 0.6);
    position: absolute;
}

.zoomable-image .close-button::before {
    top: calc((70px - 8em) / 2);
    left: calc((70px - 2em) / 2);
    width: 2em;
    height: 8em;
}

.zoomable-image .close-button::after {
    top: calc((70px - 2em) / 2);
    left: calc((70px - 8em) / 2);
    width: 8em;
    height: 2em;
}