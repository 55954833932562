.gallery-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    background-color: #fbfbfb;
    border-radius: 15px;
    padding: 30px 40px;
}

.gallery-item .title {
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    margin-bottom: 20px;
    margin-top: 0;
}

.gallery-item .list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 25px;
}

.gallery-item .list::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

.gallery-item .list > div {
    display: inline-grid;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    transition: all ease-in-out 150ms;
    aspect-ratio: 1;
}

.gallery-item .list > div:hover {
    transform: scale(1.07);
}

.gallery-item .list > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-item .list > div > button {
    position: absolute;
    cursor: pointer;
    width: 15%;
    height: 15%;
    border: none;
    border-radius: 50%;
    background-color: #ffffff30;
    top: 5%;
    right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #ffffff54;
    outline: none;
    transition: all ease-in-out 150ms;
}

.gallery-item .list > div:hover > button {
    background-color: #ffffff90;
    color: #ffffffaa;
    transform: scale(1.07);
}

.gallery-item .list div:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

.gallery-item .list div.add-image {
    background-color: white;
    border: 3px dashed #d6d6d6;
    font-size: 3rem;
    color: #d6d6d6;
    cursor: pointer;
}

.gallery-item .list div.add-image label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-item > button {
    border: none;
    border-radius: 30px;
    background-color: #0197ab;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    outline: none;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
    align-self: flex-end;
}

.gallery-item > button:hover {
    transform: scale(1.07);
}

.gallery-item > button .spinner-icon {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}