.button {
    display: inline-block;
    text-decoration: none;
    background-color: #0197ab;
    padding: 20px 40px;
    color: white;
    text-transform: uppercase;
    font-family: 'Bebas Kai', sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.2rem;
    transition: all ease-in-out 0.2s;
    text-align: center;
}

.button:hover {
    background-color: #007786;
    transform: scale(1.05);
}

.button.light-pink {
    background-color: #f0dbc8;
}

.button.light-pink:hover {
    background-color: #e2ccb8;
}

.button.blue {
    background-color: #81bfc8;
}

.button.blue:hover {
    background-color: #74afb8;
}

.button.green {
    background-color: #d2d5a9;
}

.button.green:hover {
    background-color: #c7ca99;
}

.button.red {
    background-color: #e8c2ca;
}

.button.red:hover {
    background-color: #dbafb9;
}