.booking-widget form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booking-widget form .input-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 40px;
    padding: 20px 20px;
    width: 70%;
    background-color: white;
}

.booking-widget .ui-selectmenu-button {
    width: auto;
}

.booking-widget form .input-group .input {
    overflow: hidden;
}

.booking-widget form .input-group .input input,
.booking-widget form .input-group .input .ui-selectmenu-button {
    border: none;
    padding: 0 20px;
    height: 100%;
    width: calc(100% - 2*20px);
    background-color: transparent;
    cursor: pointer;
}

.booking-widget form .input-group .input .ui-selectmenu-button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.booking-widget form .input-group .input:not(:last-of-type) {
    border-right: solid 1px #ebebeb;
}

.booking-widget form .input-group .input input,
.booking-widget form .input-group .input input::placeholder,
.booking-widget form .input-group .input .ui-selectmenu-text {
    color: black;
    font-size: 1.1rem;
    font-family: 'RobotoRegular', sans-serif;
}

.booking-widget form .submit-button {
    border: none;
    background-color: #0197ab;
    margin-top: 50px;
    padding: 20px 40px;
    color: white;
    text-transform: uppercase;
    font-family: 'Bebas Kai', sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.2rem;
    transition: all ease-in-out 0.2s;
}

.booking-widget form .submit-button:hover {
    background-color: #007786;
    transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
    .booking-widget form .input-group {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        height: auto;
        width: 60%;
        padding: 10px 50px;
    }

    .booking-widget form .input-group .input input,
    .booking-widget form .input-group .input .ui-selectmenu-button {
        padding: 20px 0;
        width: 100%;
    }

    .booking-widget form .input-group .input:not(:last-of-type) {
        border-bottom: solid 1px #ebebeb;
        border-right: none;
    }

    .booking-widget form .input-group .input:last-of-type {
        border-bottom: solid 1px transparent;
    }
}

@media screen and (max-height: 600px) {
    .booking-widget form .input-group {
        padding: 10px 30px;
    }

    .booking-widget form .input-group .input input,
    .booking-widget form .input-group .input .ui-selectmenu-button {
        padding: 10px 0;
        width: 100%;
    }

    .booking-widget form .input-group .input input,
    .booking-widget form .input-group .input input::placeholder,
    .booking-widget form .input-group .input .ui-selectmenu-text {
        font-size: 1rem;
    }
}