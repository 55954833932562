.activites .overlay,
.activites > .slider {
    height: 100vh;
}

.activites > .slider img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.activites .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(#38383854 0%, transparent 15%);
    background-color: #eacb9d24;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.activites .overlay>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activites .overlay>div:first-child h1,
.activites .overlay>div:first-child h2 {
    margin: 0;
    font-weight: normal;
    text-shadow: #6161614a 0px 0px 20px;
    text-align: center;
    padding: 0 20px;
}

.activites .overlay>div:first-child h1 {
    font-family: 'Cartel Deux Sharp', sans-serif;
    font-size: 9rem;
    color: white;
    margin-bottom: 30px;
}

.activites .overlay>div:first-child h2 {
    font-family: 'Bebas Kai', sans-serif;
    font-size: 5rem;
    letter-spacing: 0.8rem;
    color: #f1f1f1e8;
    width: 80%;
    margin-bottom: 50px;
}

.activites .overlay .button {
    margin: 0 10vw;
}

.activites .description {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8vw;
    padding: 200px 13% 100px;
    background-color: #ffffffff;
    background-image: url('/img/intro-section-background.jpg');
    background-repeat: no-repeat;
    background-size: contain;
}

.activites .description img {
    width: 90%;
    height: 100%;
    object-fit: contain;
}

.activites .description .text p {
    font-family: 'RobotoLight', sans-serif;
    letter-spacing: 0.05rem;
    line-height: 1.8rem;
    font-size: 1.2rem;
}

@media screen and (max-width: 1300px) {    
    .activites .overlay>div:first-child h2 {
        font-size: 4rem;
        letter-spacing: 0.6rem;
    }
}

@media screen and (max-width: 1000px) {
    .activites .description {
        display: flex;
        flex-direction: column;
        padding-top: 8rem;
    }

    .activites .description img {
        align-self: center;
    }

    .activites .description .text {
        padding-left: 0;
        padding-top: 5rem;
    }

    .activites .overlay>div:first-child h1 {
        font-size: 7.5rem;
    }

    .activites .overlay>div:first-child h2 {
        font-size: 3rem;
        letter-spacing: 0.4rem;
    }
}

@media screen and (max-width: 800px) {
    .activites .overlay>div:first-child h1 {
        font-size: 6rem;
    }

    .activites .overlay>div:first-child h2 {
        font-size: 2.5rem;
        letter-spacing: 0.2rem;
    }
}

@media screen and (max-width: 600px) {
    .activites .description .fancy-title h2:first-of-type {
        font-size: 6rem;
    }
    
    .activites .description .fancy-title h2:last-of-type {
        font-size: 2.5rem;
        letter-spacing: 0.3rem;
    }
}

@media screen and (max-width: 500px) {
    .activites .overlay>div:first-child h1 {
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 400px) {
    .activites .description .fancy-title h2:first-of-type {
        font-size: 5rem;
    }
    
    .activites .description .fancy-title h2:last-of-type {
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }
}