#intro-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 200px 13%;
    background-color: #ffffffff;
    background-image: url('/img/intro-section-background.jpg');
    background-repeat: no-repeat;
    background-size: contain;
}

#intro-section .fancy-title {
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#intro-section .fancy-title h2:first-of-type {
    font-size: 7rem;
}

#intro-section .fancy-title h2:last-of-type {
    font-size: 4rem;
    letter-spacing: 0.3rem;
}

#intro-section .text {
    padding-left: 50px;
}

#intro-section .text p {
    font-family: 'RobotoLight', sans-serif;
    letter-spacing: 0.05rem;
    line-height: 1.8rem;
    font-size: 1.2rem;
}

#intro-section .text p i {
    margin-top: 0.2rem;
    font-size: 0.6rem;
    vertical-align: text-top;
}

@media screen and (max-width: 1000px) {
    #intro-section {
        display: flex;
        flex-direction: column;
        padding-top: 8rem;
    }

    #intro-section .text {
        padding-left: 0;
        padding-top: 5rem;
    }
}

@media screen and (max-width: 600px) {
    #intro-section .fancy-title h2:first-of-type {
        font-size: 6rem;
    }
    
    #intro-section .fancy-title h2:last-of-type {
        font-size: 2.5rem;
        letter-spacing: 0.3rem;
    }
}

@media screen and (max-width: 400px) {
    #intro-section .fancy-title h2:first-of-type {
        font-size: 5rem;
    }
    
    #intro-section .fancy-title h2:last-of-type {
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }
}

#accomodation-section {
    position: relative;
    margin-bottom: 50px;
}

#accomodation-section .background {
    width: 100%;
    height: calc(100% - 2 * 70px);
    position: absolute;
    background-color: #f8f8f8;
    margin: 70px 0;
}

#accomodation-section .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 50px 600px) 50px;
    grid-column-gap: 11vw;
    padding: 0 15vw;
    position: relative;
}

#accomodation-section .grid .thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
}

#accomodation-section .grid .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

#accomodation-section .grid .thumbnail-text {
    display: flex;
    flex-direction: column;
}

#accomodation-section .grid .thumbnail-text h2:nth-of-type(1) {
    font-size: 6rem;
}

#accomodation-section .grid .thumbnail-text h2:nth-of-type(2) {
    font-size: 4rem;
    letter-spacing: 0.3rem;
}

#accomodation-section .grid .thumbnail-text p {
    font-family: 'RobotoLight', sans-serif;
    letter-spacing: 0.05rem;
    line-height: 1.5rem;
    font-size: 1.2rem;
}

#accomodation-section .grid > div:nth-of-type(1) {
    grid-row: 1 / 4;
    grid-column: 1;
}

#accomodation-section .grid > div:nth-of-type(2) {
    grid-row: 2;
    grid-column: 2;
    overflow: hidden;
    align-self: center;
}

#accomodation-section .grid > div:nth-of-type(3) {
    grid-row: 3 / 6;
    grid-column: 2;
}

#accomodation-section .grid > div:nth-of-type(4) {
    grid-row: 4;
    grid-column: 1;
    overflow: hidden;
    align-self: center;
}

#accomodation-section .grid > div:nth-of-type(5) {
    grid-row: 5 / 8;
    grid-column: 1;
}

#accomodation-section .grid > div:nth-of-type(6) {
    grid-row: 6;
    grid-column: 2;
    overflow: hidden;
    align-self: center;
}

#accomodation-section .grid > div:nth-of-type(7) {
    grid-row: 7 / 10;
    grid-column: 2;
}

#accomodation-section .grid > div:nth-of-type(8) {
    grid-row: 8;
    grid-column: 1;
    overflow: hidden;
    align-self: center;
}

#accomodation-section .grid > div:nth-of-type(9) {
    grid-row: 9 / 12;
    grid-column: 1;
}

#accomodation-section .grid > div:nth-of-type(10) {
    grid-row: 10;
    grid-column: 2;
    overflow: hidden;
    align-self: center;
}

#accomodation-section .grid a,
#activities-section a,
#restaurant-section a {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'RobotoRegular', sans-serif;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    font-weight: 600;
}

#accomodation-section .grid a i,
#activities-section a i,
#restaurant-section a i {
    font-size: 1.3rem;
    margin-left: 5px;
    transition: all ease-in-out 0.2s;
}

#accomodation-section .grid a:hover i,
#activities-section a:hover i,
#restaurant-section a:hover i {
    transform: translateX(10px);
}

#accomodation-section .side-title {
    transform: rotateZ(-90deg);
    display: inline-block;
    position: absolute;
    left: -35px;
    top: 100px;
    width: 0;
    height: 0;
}

#accomodation-section .side-title h3 {
    text-transform: uppercase;
    font-family: 'Bebas Kai', sans-serif;
    font-size: 8rem;
    letter-spacing: 0.8rem;
    color: #d9d9d9;
    font-weight: normal;
    margin: 0;
    position: absolute;
    right: 0;
}

#activities-section,
#restaurant-section {
    padding: 150px 15vw calc(60px + 4vw);
    display: grid;
    grid-column-gap: 10vw;
}

#activities-section {
    grid-template-columns: 25vw 35vw;
}

#restaurant-section {
    grid-template-columns: 35vw 25vw;
}

#activities-section img,
#restaurant-section img {
    width: 16vw;
    height: 16vw;
    object-fit: cover;
    box-shadow: 0 14px 38px rgba(0,0,0,0.30), 0 10px 12px rgba(0,0,0,0.22);
}

#activities-section > div:nth-child(2),
#restaurant-section > div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
}

#activities-section > div:nth-child(2) img:nth-child(2),
#restaurant-section > div:nth-child(1) img:nth-child(2) {
    position: relative;
    top: 4vw;
    margin-left: 3vw;
}

#activities-section > div:nth-child(1),
#resstaurant-section > div:nth-child(2) {
    display: flex;
    flex-direction: column;
}

#activities-section > div:nth-child(1) h2:nth-of-type(1),
#restaurant-section > div:nth-child(2) h2:nth-of-type(1) {
    font-size: 6rem;
}

#activities-section > div:nth-child(1) h2:nth-of-type(2),
#restaurant-section > div:nth-child(2) h2:nth-of-type(2) {
    font-size: 4rem;
    letter-spacing: 0.4rem;
}

#activities-section > div:nth-child(1) p,
#restaurant-section > div:nth-child(2) p {
    font-family: 'RobotoLight', sans-serif;
    letter-spacing: 0.05rem;
    line-height: 1.5rem;
    font-size: 1.2rem;
}

@media screen and (max-width: 1500px) {
    #accomodation-section .grid {
        grid-column-gap: 11vw;
        padding: 0 10vw;
    }
}

@media screen and (max-width: 1300px) {
    #accomodation-section .grid {
        grid-column-gap: 8vw;
        padding: 0 9vw;
    }
}

@media screen and (max-width: 1200px) {
    #accomodation-section .background {
        height: 100%;
        margin: 0;
    }

    #accomodation-section .grid {
        grid-column-gap: 11vw;
        padding: 0 15vw;
        display: flex;
        flex-direction: column;
        padding: 70px 15%;
    }

    #accomodation-section .thumbnail {
        width: 100%;
        padding-top: 56.25%;
        margin-bottom: 30px;
    }

    #accomodation-section .thumbnail img {
        position: absolute;
        top: 0;
        left: 0;
    }

    #accomodation-section .thumbnail-text {
        padding-top: 1rem;
    }

    #accomodation-section .thumbnail-text:not(:last-of-type) {
        padding-bottom: 8rem;
    }

    #activities-section,
    #restaurant-section {
        display: flex !important;
        padding: 80px 15vw calc(60px + 4vw) !important;
    }

    #activities-section {
        flex-direction: column;
    }

    #restaurant-section {
        flex-direction: column-reverse;
    }

    #activities-section > div:nth-child(1),
    #restaurant-section > div:nth-child(2) {
        margin-bottom: 55px;
    }

    #activities-section img,
    #restaurant-section img {
        width: 25vw !important;
        height: 25vw !important;
    }

    #activities-section > div:nth-child(2),
    #restaurant-section > div:nth-child(1) {
        justify-content: center !important;
    }

    #activities-section > div:nth-child(2) img:nth-child(2),
    #restaurant-section > div:nth-child(1) img:nth-child(2) {
        margin-left: 8vw !important;
    }
}

@media screen and (max-width: 600px) {
    #accomodation-section .thumbnail-text {
        padding-top: 2rem;
    }

    #accomodation-section .grid .thumbnail-text h2:nth-of-type(1) {
        font-size: 4rem;
    }
    
    #accomodation-section .grid .thumbnail-text h2:nth-of-type(2) {
        font-size: 3rem;
        line-height: 2rem;
        letter-spacing: 0.3rem;
    }

    #accomodation-section .side-title {
        left: -18px;
        top: 40px;
    }

    #accomodation-section .side-title h3 {
        font-size: 4rem;
        letter-spacing: 0.5rem;
    }

    #accomodation-section .grid div:nth-of-type(8) h2:nth-of-type(1),
    #accomodation-section .grid div:nth-of-type(10) h2:nth-of-type(1) {
        margin-bottom: 0px;
    }

    #activities-section .fancy-title h2:first-of-type,
    #restaurant-section .fancy-title h2:first-of-type {
        font-size: 6rem !important;
    }
    
    #activities-section .fancy-title h2:last-of-type,
    #restaurant-section .fancy-title h2:last-of-type {
        font-size: 2.5rem !important;
        letter-spacing: 0.3rem !important;
    }

    #activities-section img,
    #restaurant-section img {
        width: 31vw !important;
        height: 31vw !important;
    }
}

@media screen and (max-width: 400px) {
    #activities-section .fancy-title h2:first-of-type,
    #restaurant-section .fancy-title h2:first-of-type {
        font-size: 5rem !important;
    }
    
    #activities-section .fancy-title h2:last-of-type,
    #restaurant-section .fancy-title h2:last-of-type {
        font-size: 2rem !important;
        letter-spacing: 0.3rem !important;
    }
}

