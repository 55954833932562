.misc-prices-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    background-color: #fbfbfb;
    border-radius: 15px;
    padding: 30px 40px;
}

.misc-prices-item .title {
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    margin-bottom: 20px;
    margin-top: 0;
}

.misc-prices-item > p {
    font-weight: bold;
    font-family: "Bebas Kai", sans-serif;
    font-size: 1.05rem;
    letter-spacing: 0.05rem;
}

.misc-prices-item .form {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 150px;
}

.misc-prices-item .form label {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    margin-bottom: 10px;
    font-family: "Bebas Kai", sans-serif;
    margin-left: 30px;
}

.misc-prices-item .form input {
    border: 1px solid #e7e7e7;
    border-radius: 30px;
    background-color: white;
    padding: 15px 30px;
    font-size: 1rem;
    width: calc(100% - 30px*2);
    outline: none;
    transition: border ease-in-out 200ms;
}

.misc-prices-item .form input:focus {
    border: 1px solid #0197ab;
}

.misc-prices-item .form div {
    display: flex;
    flex-direction: column;
}

.misc-prices-item > button {
    border: none;
    border-radius: 30px;
    background-color: #0197ab;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    outline: none;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
    align-self: flex-end;
}

.misc-prices-item > button:hover {
    transform: scale(1.07);
}

.misc-prices-item > button .spinner-icon {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}