.text-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    background-color: #fbfbfb;
    border-radius: 15px;
    padding: 30px 40px;
}

.text-item .title {
    align-self: center;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    margin-bottom: 20px;
    margin-top: 0;
}

.text-item .editor {
    margin-bottom: 25px;
}

.text-item .editor > p {
    font-weight: bold;
    font-family: "Bebas Kai", sans-serif;
    font-size: 1.05rem;
    letter-spacing: 0.05rem;
}

.text-item .editor > p span {
    margin-right: 7px;
}

.text-item .save-button {
    border: none;
    border-radius: 30px;
    background-color: #0197ab;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    outline: none;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
    align-self: flex-end;
}

.text-item .save-button:hover {
    transform: scale(1.07);
}

.text-item > button .spinner-icon {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}