.cgv {
    padding-left: 15vw;
    padding-right: 15vw;
}

.cgv h2,
.cgv h3 {
    font-family: 'Bebas Kai', sans-serif;
}

.cgv h2 {
    text-align: center;
    font-size: 3rem;
}

.cgv h3 {
    font-size: 1.8rem;
}

.cgv h3:not(:first-of-type) {
    margin-top: 60px;
}

.cgv p {
    font-size: 1.2rem;
}

@media screen and (max-width: 1000px) {
    .cgv {
        padding-left: 10vw;
        padding-right: 10vw;
    }
}

@media screen and (max-width: 600px) {
    .cgv {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .cgv h2 {
        font-size: 2.5rem;
    }

    .cgv h3 {
        font-size: 1.5rem;
    }
}