.img-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    background-color: #fbfbfb;
    border-radius: 15px;
    padding: 30px 60px;
}

.img-item .title {
    grid-column: 1 / 3;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    margin-bottom: 20px;
    margin-top: 0;
}

.img-item .form {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-item .form label:first-of-type {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    margin-bottom: 20px;
    font-family: "Bebas Kai", sans-serif;
}

.img-item .form label:last-of-type {
    border-radius: 10px;
    background-color: #0197ab;
    padding: 20px 40px;
    color: white;
    font-size: 1.1rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
}

.img-item .form label:last-of-type i {
    margin-left: 10px;
}

.img-item .form label:last-of-type:hover {
    transform: scale(1.07);
}

.img-item .form input:first-of-type {
    margin-bottom: 20px;
}

.img-item .preview {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-item .preview img {
    width: 20vw;
    height: 20vw;
    object-fit: contain;
}

.img-item > button {
    border: none;
    border-radius: 30px;
    background-color: #0197ab;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    outline: none;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
    grid-column: 2;
    justify-self: end;
}

.img-item > button:hover {
    transform: scale(1.07);
}

.img-item > button .spinner-icon {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}