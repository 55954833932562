.navbar {
    background-color: white;
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 80px;
    box-shadow: 0px -10px 20px 0px #3c3c3c;
    transition: all ease-in-out 0.3s;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
    align-items: center;
}

.navbar a {
    text-decoration: none;
    color: black;
    font-family: 'Bebas Kai', sans-serif;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.2s;
}

.navbar > a:hover {
    transform: scale(1.1);
    letter-spacing: 0.2rem;
}

.navbar .logo {
    height: 100%;
    width: 80px;
    background-image: url('/img/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.navbar .logo a {
    display: block;
    width: 100%;
    height: 100%;
}

.navbar.homepage.top {
    background-color: transparent;
    box-shadow: none;
}

.navbar.homepage.top a {
    color: white;
}

.navbar.homepage.top .logo {
    background-image: url('/img/logo-light.png');
}

.navbar .icon {
    display: none;
    font-size: 1.5rem;
    color: black;
    margin-right: 30px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}

.navbar .icon:hover {
    transform: scale(1.2);
}

.navbar.homepage.top .icon {
    color: white;
}

@media screen and (max-width: 1000px) {
    .navbar {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 80px repeat(6, auto);
    }

    .navbar .logo {
        grid-row: 1;
        grid-column: 1;
        justify-self: start;
        padding-left: 15px;
    }

    .navbar .icon {
        display: block;
        grid-row: 1;
        grid-column: 2;
        justify-self: end;
    }

    .navbar a:not(.logo) {
        grid-column: 1 / 3;
        display: none;
    }

    .navbar.open {
        height: auto;
        background-color: white !important;
        padding-bottom: 20px;
        box-shadow: 0px -10px 20px 0px #3c3c3c;
    }

    .navbar.open .logo {
        background-image: url('/img/logo.png') !important;
    }

    .navbar.open .icon {
        color: black !important;
    }

    .navbar.open a:not(.logo) {
        display: flex;
    }

    .navbar.open > a:not(.logo) {
        color: black;
        padding: 10px 0;
        font-size: 1.5rem;
    }
}

@media screen and (max-height: 600px) {
    .navbar {
        height: 50px;
        grid-template-rows: 50px repeat(6, auto);
    }

    .navbar .logo {
        width: 50px;
        padding-left: 10px;
    }

    .navbar .icon {
        margin-right: 15px;
    }
}