#admin-login {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./assets/thumbnail.jpg");
    background-position: center;
    background-size: cover;
}

#admin-login form {
    width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 50px 100px;
    border-radius: 15px;
}

#admin-login form img {
    object-fit: contain;
    margin: 0 20px 30px;
}

#admin-login form .input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

#admin-login form .input label {
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    margin-bottom: 10px;
    font-family: "Bebas Kai", sans-serif;
    font-weight: bold;
}

#admin-login form .input input {
    border: 1px solid #0197ab00;
    border-radius: 30px;
    background-color: #f7f7f7;
    padding: 15px 30px;
    font-size: 1rem;
    width: 100%;
    outline: none;
    transition: border ease-in-out 200ms;
}

#admin-login form .input input:focus {
    border: 1px solid #0197ab;
}

#admin-login form button {
    border: none;
    border-radius: 30px;
    background-color: #0197ab;
    padding: 15px 30px;
    color: white;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    outline: none;
    align-self: center;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
}

#admin-login form button:hover {
    transform: scale(1.07);
}