.infos .banner {
    height: 50vh;
}

.infos .banner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.infos .banner .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 50vh;
    width: 100%;
    background-image: linear-gradient(#38383854 0%, transparent 30%);
    background-color: #085a6361;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infos .banner .overlay>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infos .banner .overlay>div:first-child h1,
.infos .banner .overlay>div:first-child h2 {
    margin: 0;
    font-weight: normal;
    text-shadow: #6161614a 0px 0px 20px;
    text-align: center;
    padding: 0 20px;
}

.infos .banner .overlay>div:first-child h1 {
    font-family: 'Cartel Deux Sharp', sans-serif;
    font-size: 8rem;
    color: white;
    margin-bottom: 15px;
}

.infos .banner .overlay>div:first-child h2 {
    font-family: 'Bebas Kai', sans-serif;
    font-size: 5rem;
    letter-spacing: 0.9rem;
    color: #f1f1f1e8;
}

.infos .content {
    padding: 150px 20%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 200px;
}

.infos .content .fancy-title {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
}

.infos .content .fancy-title h2:nth-child(1) {
    font-size: 5rem;
}

.infos .content .fancy-title h2:nth-child(2) {
    font-size: 4rem;
}

.infos .content p {
    font-size: 1.3rem;
    position: relative;
}

.infos .content > div:nth-child(2) p {
    padding: 30px 0;
    margin: 0;
}

.infos .content > div:nth-child(2) {
    align-self: center;
    width: 100%;
}

.infos .content > div:nth-child(2) p:nth-child(2) {
    text-align: end;
}

.infos .content .background-icon {
    position: absolute;
    font-size: 5rem;
    z-index: -1;
}

.infos .content > div:nth-child(2) p:nth-child(1) .background-icon {
    left: 26px;
    top: 0;
    color: #81bfc840;
}

.infos .content > div:nth-child(2) p:nth-child(2) .background-icon {
    right: 26px;
    top: 0;
    color: #81bfc840;
    transform: rotateZ(15deg);
}

.infos .content > div:nth-child(4) p {
    text-align: center;
}

.infos .content > div:nth-child(4) iframe {
    border: none;
    width: 100%;
    height: 400px;
}

.infos .content > div:nth-child(6) > div:last-child {
    display: flex;
    justify-content: center;
}

.infos .content > div:nth-child(8) img {
    width: 100%;
}

@media screen and (max-width: 1100px) {
    .infos .content {
        padding: 150px 15%;
    }
}

@media screen and (max-width: 1000px) {
    .infos .content {
        display: flex;
        flex-direction: column;
    }

    .infos .content > div:nth-child(2n):not(:last-child) {
        margin-bottom: 150px;
    }

    .infos .content > div:nth-child(2n+1) {
        margin-bottom: 50px;
    }

    .infos .content .fancy-title {
        align-items: center;
    }
}

@media screen and (max-width: 800px) {
    .infos .banner .overlay>div:first-child h1 {
        font-size: 6rem;
    }
    
    .infos .banner .overlay>div:first-child h2 {
        font-size: 4rem;
        letter-spacing: 0.5rem;
    }
}

@media screen and (max-width: 500px) {
    .infos .banner .overlay>div:first-child h1 {
        font-size: 5rem;
    }
    
    .infos .banner .overlay>div:first-child h2 {
        font-size: 3rem;
        letter-spacing: 0.4rem;
    }    

    .infos .content p {
        font-size: 1.1rem;
    }

    .infos .content .fancy-title h2:nth-child(1) {
        font-size: 4rem;
    }
    
    .infos .content .fancy-title h2:nth-child(2) {
        font-size: 3rem;
    }
}

@media screen and (max-width: 400px) {
    .infos .banner .overlay>div:first-child h1 {
        font-size: 4rem;
    }
    
    .infos .banner .overlay>div:first-child h2 {
        font-size: 2.5rem;
    }
}