#account-mgt {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    min-height: 100%;
}

#account-mgt h1 {
    align-self: center;
    background-color: #0197ab;
    padding: 12px 50px;
    color: white;
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    margin: 0;
    margin-bottom: 40px;
}

#account-mgt .list {
    margin-bottom: 100px;
}

#account-mgt .legend,
#account-mgt .user-item {
    display: grid;
    align-items: center;
    justify-items: start;
    grid-template-columns: 1fr 8fr 6fr 4fr 2fr;
    padding: 15px 40px;
}

#account-mgt .legend p {
    color: #94959a;
    font-weight: bold;
}

#account-mgt .legend p:first-of-type {
    grid-column: 2;
}

#account-mgt .user-item {
    background-color: #fbfbfb;
    border-radius: 10px;
    margin-bottom: 5px;
}

#account-mgt .user-item .id {
    font-weight: bold;
    color: #94959a;
}

#account-mgt .user-item .email {
    font-weight: bold;
}

#account-mgt .user-item .password,
#account-mgt .user-item .role {
    color: white;
    padding: 3px 12px;
    border-radius: 7px;
    font-weight: bold;
}

#account-mgt .user-item .password.change {
    background-color: #F0A92E;
}

#account-mgt .user-item .password.definitive {
    background-color: #47D764;
}

#account-mgt .user-item .role.admin {
    background-color: #0197AB;
}

#account-mgt .user-item .role.superadmin {
    background-color: #ff355c;
}

#account-mgt .user-item .actions {
    display: flex;
}

#account-mgt .user-item .actions button {
    display: flex;
    margin-right: 25px;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding: 7px;
    border-radius: 6px;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
}

#account-mgt .user-item .actions button:hover {
    transform: scale(1.1);
}

#account-mgt .user-item .actions button.reset {
    background-color: #F0A92E;
}

#account-mgt .user-item .actions button.delete {
    background-color: #ff355c;
}

#account-mgt form {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
}

#account-mgt form .input {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    align-items: center;
}

#account-mgt form .input label {
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    margin-bottom: 10px;
    font-family: "Bebas Kai", sans-serif;
}

#account-mgt form .input input {
    border: 1px solid #0197ab00;
    border-radius: 30px;
    background-color: #f7f7f7;
    padding: 15px 30px;
    font-size: 1rem;
    width: 100%;
    outline: none;
    transition: border ease-in-out 200ms;
}

#account-mgt form .input input:focus {
    border: 1px solid #0197ab;
}

#account-mgt form button,
#account-mgt .modal > div button {
    border: none;
    border-radius: 30px;
    background-color: #0197ab;
    padding: 10px 20px;
    color: white;
    font-size: 1.1rem;
    letter-spacing: 0.05rem;
    outline: none;
    align-self: center;
    font-family: "Bebas Kai", sans-serif;
    cursor: pointer;
    transition: transform ease-in-out 100ms;
}

#account-mgt form button:hover,
#account-mgt .modal > div button:hover {
    transform: scale(1.07);
}

#account-mgt .modal {
    position: absolute;
    background-color: #ffffff8f;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#account-mgt .modal > div {
    background-color: white;
    box-shadow: 0px 5px 20px 0px rgb(0 0 0 / 8%);
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#account-mgt .modal > div h2 {
    margin: 0;
    font-family: "Bebas Kai", sans-serif;
    letter-spacing: 0.1rem;
}

#account-mgt .modal > div p {
    margin-top: 50px;
    margin-bottom: 50px;
    font-family: "Bebas Kai", sans-serif;
    letter-spacing: 0.05rem;
    font-size: 1.2rem;
}