#edit-page {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
    min-height: 100%;
}

#edit-page > h1 {
    align-self: center;
    background-color: #0197ab;
    padding: 12px 50px;
    color: white;
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Kai", sans-serif;
    margin: 0;
    margin-bottom: 40px;
}